type RequestOptions = {
    url: string
    method?: string
    payload?: unknown
}

export const mediaHeaders = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
};

// ngrok http https://localhost:44395/
export const baseEndpoint = 'https://app.load1.com/api/MobileCustomer/';

export const fetchApi = async <TResponse>(effect: RequestOptions): Promise<TResponse> => {

    const method = (effect.method || 'get').toUpperCase();
    const payload = effect.payload || {};
    const token = localStorage.getItem("TOKEN");

    const headers = token ? {
        ...mediaHeaders,
        Authorization: `Bearer ${token}`,
    } : mediaHeaders;

    const options = {
        body: JSON.stringify(payload),
        headers: headers,
        method: method.toLowerCase(),
    } as RequestInit;

    if (method !== 'POST' && Object.keys(payload).length === 0) {
        delete options.body;
    }

    const url = `${baseEndpoint}${effect.url}`;
    const response = await fetch(url, options);
    if (response.status >= 200 && response.status < 300) {
        return response.json();
    }
    throw new Error(`${response.status}`);
}

export const fetchFileApi = async (effect: RequestOptions) => {

    const method = (effect.method || 'get').toUpperCase();
    const payload = effect.payload || {};

    const headers = {
        Authorization: `Bearer ${localStorage.getItem("TOKEN")}`,
    }

    const options = {
        body: JSON.stringify(payload),
        headers: headers,
        method: method.toLowerCase(),
    } as RequestInit;

    if (method !== 'POST' && Object.keys(payload).length === 0) {
        delete options.body;
    }

    const url = `${baseEndpoint}${effect.url}`;
    const response = await fetch(url, options);
    if (response.status >= 200 && response.status < 300) {
        return response.blob();
    }
    throw new Error(`${response.status}`);
}